<template>
  <v-container class="pa-0" fluid>
    <v-card class="py-10">
      <div class="card-header-padding pb-0">
        <h4 class="font-weight-bold text-h4 text-typo mb-0">Xоолны xуваарь</h4>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-select
              :items="[1, 2, 3, 4]"
              v-model="currentSelectedSemester"
              label="Улирал сонгоx"
            >
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              style="background-color: #ffe082"
              v-model="currentSelectedWeek"
              :items="_getWeeks()"
              label="Долоо хоног сонгоx"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
      <div class="card-header-padding pb-0">
     
        <v-simple-table class="bborder" style="border-bottom: 1px solid #bbb">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="
                    border-right: 1px solid #bbb;
                    border-left: 1px solid #bbb;
                    width: 1%;
                  "
                >
                  No
                </th>
                <th style="">Нэр</th>

                <!-- <th style="width: 8%">Цаг</th> -->
                <th
                  style="width: 200px"
                  v-for="(day, dindex) in days"
                  :key="'calendar-' + day.dayIndex + dindex"
                  class="text-center font-weight-bold"
                >
                  {{ day.name }}
                  <span class="blue--text"> {{ _getDate(day) }}</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(xeelj, lindex) in availableTimes"
                :key="xeelj + lindex + 'eelj'"
              >
                <td style="border-left: 1px solid #bbb; border-right: 0">
                  {{ lindex + 1 }}
                </td>
                <td>
                  {{ xeelj.name }}
                </td>
                <template v-for="(day, dindex) in days">
                  <td
                    :key="'cell-' + day.dayIndex + xeelj.index + dindex"
                    @click="_clickedCell(day, xeelj)"
                    :title="''"
                    style="cursor: pointer"
                    class="px-1 mt-0"
                  >
                    <v-card
                      style="border-radius: 5px"
                      class="pa-2 ma-1 d-flex flex-row"
                      v-if="_getCellData(day, xeelj)"
                      :style="_getColorData()"
                    >
                      <v-img
                        width="50"
                        height="50"
                        style="border-radius: 5px"
                        :src="
                          _getCellData(day, xeelj).selectedFood.images &&
                          _getCellData(day, xeelj).selectedFood.images.length >
                            0
                            ? _getCellData(day, xeelj).selectedFood.images[0]
                                .fileUrl
                            : 'https://placehold.co/200x200/000000/000000'
                        "
                      ></v-img>
                      <span class="ml-1">{{
                        _getCellData(day, xeelj).selectedFood.name.slice(3)
                      }}</span>
                    </v-card>
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>

    <v-dialog v-model="selectingFoodDialog" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h4 text-typo justify-center"
          v-if="cookingProducts"
          >Хоолоо сонгоно уу!</v-card-title
        >
        <v-card-actions>
          <v-select
            :disabled="selectedFood && selectedFood.saved"
            v-if="cookingProducts"
            return-object
            v-model="selectedFood"
            :items="cookingProducts"
            item-text="name"
            item-value="lessonId"
            label="Xоол сонгоx"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-select
            v-if="selectedFood && selectedFood.saved && classGroups"
            return-object
            v-model="selectedClassGroup"
            :items="classGroups"
            item-text="fullName"
            item-value="classGroupRef"
            label="Ангиа сонгоx"
          ></v-select>
        </v-card-actions>
        <v-card-actions
          v-if="selectedFood && selectedFood.saved"
          class="d-flex flex-row justify-space-around"
        >
          <v-btn
            style="width: 100%"
            @click="_saveCalendarData"
            class="btn-primary bg-gradient-primary text-capitalize"
            >Өдрийн хоол бүртгэх</v-btn
          >
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn
            @click="_saveCalendarData"
            class="btn-primary bg-gradient-primary text-capitalize"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteConfirmDialog" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та xичээлээ устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteConfirmDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >

          <v-btn
            @click="_deleteConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      classGroups: null,
      availableTimes: null,
      currentWeekDays: null,
      currentSelectedWeek: null,
      selectingFoodDialog: false,
      selectedFood: null,
      selectedClassGroup: null,
      selectedDay: null,
      selectedXeelj: null,
      deleteConfirmDialog: false,

      currentSelectedSemester: null,
      calendarColors: [
        {
          subjectId: 1,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 2,
          fgColor: "#42A5F5",
          bgColor: "white",
        },
        {
          subjectId: 3,
          fgColor: "#90CAF9",
          bgColor: "black",
        },
        {
          subjectId: 4,
          fgColor: "#BBDEFB",
          bgColor: "black",
        },
        {
          subjectId: 5,
          fgColor: "#F48FB1",
          bgColor: "black",
        },
        {
          subjectId: 6,
          fgColor: "#C8E6C9",
          bgColor: "black",
        },
        {
          subjectId: 7,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 8,
          fgColor: "#81C784",
          bgColor: "black",
        },
        {
          subjectId: 9,
          fgColor: "#9575CD",
          bgColor: "white",
        },
        {
          subjectId: 10,
          fgColor: "#2962FF",
          bgColor: "white",
        },

        {
          subjectId: 11,
          fgColor: "#66BB6A",
          bgColor: "white",
        },

        {
          subjectId: 12,
          fgColor: "#FFF176",
          bgColor: "black",
        },
        {
          subjectId: 13,
          fgColor: "#FFD54F",
          bgColor: "black",
        },
        {
          subjectId: 14,
          fgColor: "#FFCA28",
          bgColor: "black",
        },
        {
          subjectId: 15,
          fgColor: "#FFAB91",
          bgColor: "black",
        },
        {
          subjectId: 16,
          fgColor: "#FF8A65",
          bgColor: "black",
        },
        {
          subjectId: 17,
          fgColor: "#FF7043",
          bgColor: "white",
        },
        {
          subjectId: 18,
          fgColor: "#B0BEC5",
          bgColor: "black",
        },
        {
          subjectId: 19,
          fgColor: "#F4511E",
          bgColor: "white",
        },
        {
          subjectId: 20,
          fgColor: "#E040FB",
          bgColor: "white",
        },
        {
          subjectId: 21,
          fgColor: "#40C4FF",
          bgColor: "black",
        },
        {
          subjectId: 22,
          fgColor: "#00E676",
          bgColor: "black",
        },
        {
          subjectId: 23,
          fgColor: "#DCE775",
          bgColor: "black",
        },
        {
          subjectId: 24,
          fgColor: "#FF5252",
          bgColor: "white",
        },
        {
          subjectId: 25,
          fgColor: "#FFE082",
          bgColor: "black",
        },
        {
          subjectId: 26,
          fgColor: "#B2DFDB",
          bgColor: "black",
        },
        {
          subjectId: 27,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
        {
          subjectId: 28,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
      ],
      days: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхагва", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
        // { name: "Бямба", dayIndex: 6 },
        // { name: "Ням", dayIndex: 7 },
      ],
      xeelj: null,
      cookingProducts: null,
      cookingCalendarData: null,
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this._setupp();
  },
  watch: {
    currentSelectedSemester() {
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      this.currentWeekDays = [];
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
    currentSelectedWeek() {
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      this.currentWeekDays = [];
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
  },
  methods: {
    _getWeeks() {
      var xx = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (xx) return xx.weeks;
      else return null;
    },
    _deleteConfirm() {},
    _deleteCalenderData() {},
    async _clickedCell(day, xeelj) {
      this.selectedDay = day;
      this.selectedXeelj = xeelj;

      var food = null;
      if (this.cookingCalendarData) {
        for (var ca of this.cookingCalendarData) {
          if (
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
          ) {
            food = ca;
            break;
          }
        }
      }
      if (food) {
        this.selectedFood = food.selectedFood;
        this.selectedFood.saved = true;
      }
      this.selectingFoodDialog = true;
    },
    _getColorData() {
      const rng = Math.floor(
        Math.random() * (this.calendarColors.length - 1 - 1 + 1) + 1
      );
      return (
        "background-color:" +
        this.calendarColors[rng].fgColor +
        ("; color:" + this.calendarColors[rng].bgColor)
      );
    },
    _getCellData(day, xeelj) {
      var food;
      if (this.cookingCalendarData) {
        for (var ca of this.cookingCalendarData) {
          if (
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
          ) {
            food = ca;
            break;
          }
        }
      }
      return food;
    },
    _getDate(day) {
      var cc = this.currentWeekDays
        ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;
      if (cc) return cc.month + "/" + cc.day;
      else return null;
    },
    _saveCalendarData() {
      if (this.selectedFood.saved) {
        console.log(this.selectedClassGroup.fullName, "hool idsen");
        this.selectingFoodDialog = false;
        this.$swal.fire({
          title:
            this.selectedClassGroup.fullName +
            " бүлгийн хоолны бүртгэлийг хийлээ!",
          text: "Өдрийн ирцээр бүртгэлээ!",
        });
        this.selectedClassGroup = null;
      } else {
        this.userData.school.ref
          .collection("cookingCalendar")
          .doc()
          .set(
            {
              createdAt: new Date(),
              selectedFood: this.selectedFood,
              createdBy: this.userData.ref,
              selectedDay: this.selectedDay,
              selectedXeelj: this.selectedXeelj,
            },
            { merge: true }
          )
          .then(() => {
            this.selectingFoodDialog = false;
            this.selectedFood = null;
          });
      }
    },
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      // var year = date.getFullYear()
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      this.$store.state.calendarButez2.forEach((sem) => {
        sem.months.forEach((mm) => {
          if (mm.name == month) {
            mm.days.forEach((dd) => {
              if (dd.day == day) {
                cc = dd.weekNumber;
                return;
              }
            });
          }
        });
      });
      return cc;
    },
    async _setupp() {
      this.currentSelectedSemester = 4;
      this.currentSelectedWeek = this._getCurrentSchoolWeekNumber();
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      this.currentWeekDays = [];
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
      this.userData.school.ref.collection("xeelj").onSnapshot((docs) => {
        this.availableTimes = [];
        var counter = 1;
        docs.forEach((doc) => {
          let eelj = doc.data();
          eelj.id = doc.id;
          eelj.ref = doc.ref;
          eelj.index = counter;
          counter++;
          this.availableTimes.push(eelj);
        });
      });
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .orderBy("index", "asc")
        .get()
        .then((docs) => {
          this.classGroups = [];
          docs.forEach(async (doc) => {
            let department = doc.data();
            department.ref = doc.ref;
            department.id = doc.id;

            await department.ref
              .collection("programs")
              .orderBy("STUDENT_GROUP_NAME", "asc")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  let program = doc.data();
                  program.ref = doc.ref;
                  program.id = doc.id;
                  program.department = department;
                  this.classGroups.push(program);
                });
              });
          });
        });

      this.userData.ref //make school please
        .collection("_cookingProducts")
        .onSnapshot((docs) => {
          this.cookingProducts = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let food = doc.data();
            food.ref = doc.ref;
            food.lessonId = doc.id;
            food.index = counter;
            food.name = counter + ". " + food.name;
            this.cookingProducts.push(food);
          });
        });

      this.userData.school.ref
        .collection("cookingCalendar")
        .onSnapshot((docs) => {
          this.cookingCalendarData = [];
          docs.forEach((doc) => {
            let calData = doc.data();
            calData.ref = doc.ref;
            calData.id = doc.id;
            this.cookingCalendarData.push(calData);
          });
        });
    },
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

.v-chip .v-chip__content {
  align-items: start !important;
  display: block;
  height: 100%;
  max-width: 100%;
}

.addIcon:hover {
  font-size: 34px;
  transition: font-size 0.2s;
  /* Add a smooth transition for a nicer effect */
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
